<template>
  <b-row>
    <b-col>
      <b-row
        v-if="relatedLevel.id === null"
        class="mt-2"
      >
        <b-col
          cols="4"
          class="mt-1"
        >
          <label class="menu-label">
            {{ $t('components.form.prices.activity') }}
          </label>
        </b-col>
        <b-col>
          <activities
            :card-levels="cardLevels"
            :related-level="relatedLevel"
            @on:activity:selected="onActivitySelect"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="mt-1"
          :class="relatedLevel.id ? 'd-flex align-items-center' : ''"
        >
          <label
            class="menu-label"
            :class="relatedLevel.id ? 'ml-4' : ''"
          >
            {{ $t('views.client.details.activities.right-content.level.level-list') }}
          </label>
        </b-col>
        <b-col
          v-if="relatedLevel.activity && levels.length > 0"
          class="radios-scroll"
        >
          <b-row
            v-for="(level, key) in levels"
            :key="'radio' + key"
          >
            <b-col
              class="d-flex mt-1"
            >
              <vs-radio
                v-model="relatedLevel.activityLevels"
                :vs-value="level['@id']"
                class="pointer ml-1"
                color="#0B2772"
                @change="onLevelSelect"
              >
                <div class="radio-label ml-1">
                  {{ level.label }}
                </div>
              </vs-radio>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-else>
          <label class="level-description">
            {{
              relatedLevel.activity === null ? $t('views.client.details.activities.right-content.level.title') : "-"
            }}
          </label>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      v-if="relatedLevel.id === null"
      cols="5"
    >
      <div v-if="relatedLevel.activity">
        <label class="menu-label mt-2">{{ $t('recurring.resume') }}</label>
        <card-level
          class="mt-1"
          :card="card"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {fromIriReferenceToId} from "@/utils/form";
import {getLevelsByActivity} from "@api/doinsport/services/activity/level/level.api";

export default {
  components: {
    Activities: () => import('@custom/clients/level/Activities'),
    CardLevel: () => import('@views/client/details/activities/CardLevel')
  },
  data: () => ({
    levels: [],
    card: {
      name: null,
      colors: [],
      userCurrentLevelIdentifier: null,
      userCurrentLevelLabel: null,
      clientBookingsCount: 'X',
      clientMatchesCount: 'Y',
      icon: null,
    },
  }),
  props: {
    relatedLevel: {
      type: Object,
      default: this
    },
    cardLevels: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getActivityId(iri) {
      return fromIriReferenceToId('/activities/', iri);
    },
    onActivitySelect(activityDetails) {
      this.loadActivityLevels();

      this.relatedLevel.activityLevels = null;
      this.card.name = activityDetails.name;
      this.card.colors = activityDetails.colors;
      this.card.icon = activityDetails.icon;
      this.card.userCurrentLevelIdentifier = null;
      this.card.userCurrentLevelLabel = null;
    },
    onLevelSelect() {
      const level = this.levels.find(el => el['@id'] === this.relatedLevel.activityLevels);

      this.card.userCurrentLevelIdentifier = level.identifier;
      this.card.userCurrentLevelLabel = level.label;
    },

    loadActivityLevels() {
      if (this.relatedLevel.activity !== null) {
        getLevelsByActivity(this.getActivityId(this.relatedLevel.activity))
          .then(response => this.levels = response.data['hydra:member'])
        ;
      }
    }
  }
  ,
  created() {
    this.loadActivityLevels();
  },
  mounted() {
    this.$bus.$on('on:card:selected', () => {
      this.loadActivityLevels();
    })
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:card:selected');
    } catch (e) {
    }
  }
}
</script>
<style scoped lang="scss">
.menu-label {
  text-align: left;
  font: normal normal 800 14px Avenir;
  letter-spacing: 0;
  color: #27272b;
  opacity: 1;
}

.custom-spinner {
  display: block;
  margin: 0 auto;
}

.level-description {
  text-align: left;
  font: normal normal normal 12px/21px Avenir;
  letter-spacing: 0.2px;
  color: #565555;
  opacity: 1;
}

.radio-label {
  text-align: left;
  font: normal normal medium 12px/24px Avenir;
  letter-spacing: 0.56px;
  color: #2E2E2E;
  opacity: 1;
}
</style>
