import {_get} from "@api/doinsport/services/httpService";
import store from "@/plugins/store";

const URI = "/activities/";

const URI_LEVELS = "levels";

export const getLevelsByActivity = (id) => _get(
  URI +
  URI_LEVELS +
  '?activity.id='+ id +
  '&country=' + store.getters["auth/currentClub"].country +
  '&itemsPerPage=100'+
  '&page=1'
);
